<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="520px"
    @close="handleClose"
  >
    <el-image style="width: 100%; min-height: 400px" :src="imgUrl" :fit="fit" />
    <div style="margin: 10px 0 0 0; font-size: 16px">
      H5链接地址:
      <span style="margin-left: 5px; font-size: 14px">
        {{ h5Url || '暂无' }}
      </span>
    </div>
    <div class="d-flex align-center">
      <el-button
        v-if="h5Url"
        class="mt-20 w-percent-100"
        type="primary"
        size="large"
        @click="copy(h5Url, $event)"
      >
        复制粘贴
      </el-button>
      <el-button
        class="mt-20 w-percent-100"
        size="large"
        type="primary"
        @click="handleClose"
      >
        我知道了
      </el-button>
    </div>
  </el-dialog>
</template>
<script setup>
  import { ref } from 'vue'
  import { debounce } from '@/utils/debounce'
  import { shareTicketsCode } from '@/api/exhibitionManage'
  import handleClipboard from '@/utils/clipboard'
  const props = defineProps({
    title: {
      type: String,
      default: '分享链接',
    },
  })
  const dialogFormVisible = ref(false)
  const h5Url = ref('')
  const imgUrl = ref('')
  const ticketCode = ref('')
  const fit = ref('cover')
  const copy = (url, event) => {
    handleClipboard(url, event)
  }
  const handleClose = () => {
    dialogFormVisible.value = false
  }
  const handleOpen = debounce(async (row = {}) => {
    ticketCode.value = row?.ticketCode || ''
    try {
      let resp = await shareTicketsCode(ticketCode.value)
      let { h5, qrCode } = resp.data
      h5Url.value = h5
      imgUrl.value = qrCode
    } catch (error) {
    } finally {
      dialogFormVisible.value = true
    }
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
